import React from 'react';
import { useView } from '@context/view';
import Threads from '@components/Threads/Threads';
import Messages from '@components/Threads/Messages';
import Pagination from '@components/Pagination';
import './SplitView.scss';

function SingleView() {
  const { thread, setThread, setThreadTitle, folder } = useView();

  const resetToThreadView = () => {
    setThread(null);
    setThreadTitle('');
  }
  const isMessageView = (!!thread || folder === 'drafts');
  return (
    <>
      <div data-testid="single-view-panel" className='oms--single-view oms--split-view'>
        {!isMessageView && <div className='oms-left-panel-outer'>
          <div className='oms-left-panel-inner'>
            <section className='oms--main-panel'>
              <Threads />
              <footer className='oms--footer'>
                <Pagination />
              </footer>
            </section>
          </div>
        </div>
        }
        {isMessageView && (
          <>
            <div className='oms-right-panel-outer'>
              <div className='oms-right-panel-inner'>
                <section data-testid="messages-panel" className='oms--secondary-panel'>
                  <Messages resetToThreadView={resetToThreadView} />
                </section>
              </div>
            </div>
          </>
        )}

      </div>
    </>
  )
}

export default SingleView;
