import React, { useEffect, useState } from 'react'
import './loader.scss';
import { useLang } from '../../../context/lang';

export function LoaderWidget ({ label = 'Loading'}) {
    return (
        <div className="loader-container" role='alert' aria-busy='false' aria-live='assertive' >
            <div data-testid='loader' className="loader" />
            <p aria-label={label}>{label}</p>
        </div>
    )
}
    
function Loader ({
    loading,
    label,
    children,
}) {
    const { dict } = useLang();
    const [message, setMessage] = useState(dict.loadingLabel.label);

    useEffect(() => {
        if (label) {
            setMessage(label);
        }
    }, [label])

    return loading ? <LoaderWidget label={message} /> : children
}
    
export default Loader
        