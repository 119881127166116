import React from 'react';
import { useState } from 'react';
import { useAnalytics } from './analytics';
import { useEffect } from 'react';

const FieldErrorsContext = React.createContext(null);

function FieldErrorsProvider({children}) {
    const { publish } = useAnalytics();
    const [fieldErrors, setFieldErrors] = useState([]);
    
    useEffect(() => {
        if (fieldErrors.length > 0) {
            let fields = '', name = '';

            fieldErrors.forEach((err, idx) => {
                fields +=
                    idx === 0
                        ? 'field' + (idx + 1) + ':' + err.id
                        : '|' + 'field' + (idx + 1) + ':' + err.id
                name += idx === 0 ? err.message : '|' + err.message
            })
            publish({ resource: 'fields' }, { fields, name });
        }
    }, [fieldErrors])

    const provider = {
        fieldErrors,
        setFieldErrors,
    }

    return <FieldErrorsContext.Provider value={provider}>{children}</FieldErrorsContext.Provider>
}

function useFieldErrors() {
    const context = React.useContext(FieldErrorsContext);
    if (!context) {
        throw new Error('useFieldErrors can only be used within a FieldErrorsProvider');
    }
    return context;
}

export { FieldErrorsProvider, useFieldErrors }