import React from 'react';
import { useView } from '@context/view';
import Threads from '@components/Threads/Threads';
import Messages from '@components/Threads/Messages';
import Pagination from '@components/Pagination';
import './SplitView.scss';

function SplitView() {
    const { folder } = useView();

    return (
        <>
        <div data-testid="split-view-panel" className='oms--split-view' id={`panel-${folder}`}>
            <div className='oms-left-panel-outer'>
                <div className='oms-left-panel-inner'>
                    <section className='oms--main-panel'>
                        <Threads />
                        <footer className='oms--footer'>
                            <Pagination />
                        </footer>
                    </section>
                </div>
            </div>
            
            <div className='oms-right-panel-outer'>
                <div className='oms-right-panel-inner'>
                    <section className='oms--secondary-panel'>
                        <Messages />
                    </section>
                </div>
            </div>
        </div>
        </>
    )
}

export default SplitView;
