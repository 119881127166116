import React from 'react';
import './SplitButton.scss';

function SplitButton({children}) {

    return (
        <div className='oms--split-button'>
            {children}
        </div>
    )
}

export default SplitButton;