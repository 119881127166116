import React, { useState, useEffect } from 'react';
import { useConfig } from '@context/config';
import { useLang } from '@context/lang';
import { useView } from '@context/view';
import { useThreads } from '@context/threads';
import './Folders.scss';
import { useRef } from 'react';
import ScreenReaderOnly from './ScreenReaderOnly';

function Folders() {
    const { isMobile } = useConfig();
    const { manager } = useView();

    return (
        <div className='oms--message-center-menu-section'>
            {manager === 'browse' && 
                (isMobile ? <FolderSelect /> : <FolderTabs />)}
        </div>
    )
}

function FolderTabs() {
    const { dict } = useLang();
    const { manager, folder, setFolder, setPage, } = useView();
    const { allowDrafts } = useConfig();
    const folderListRef = useRef(null);
    const { unreadMsgCount } = useThreads();

    const handleFolderChange = (folderType) => {
        setFolder(folderType);
        setPage(1);
    }

    const additionalTabProps = (folderType) => {
        const isMatchingFolder = folder === folderType;
        return {
            tabIndex: "0",
            role: "tab",
            ["aria-controls"]: `panel-${folderType}`,
            ["aria-selected"]: isMatchingFolder ? "true": "false"
        }
    }

    useEffect(() => {
        if(manager === 'browse'){
            folderListRef.current.focus();
        }
    }, [manager])

    const buildRoute = () => {
        const { origin = '', pathname = '' } = window.location;
        const currentHashRoute = window.location.hash;
        const route = `${origin}${pathname}` + (currentHashRoute.startsWith('#') ? currentHashRoute : '#');
        return route;
    }

    return (
	     <div className='oms--message-center-tabs'>
         { manager === 'browse' &&
        <ul role='tablist' className='oms--folder-tabs' data-testid='oms-folder-tabs' ref={folderListRef}>
          <li>
            <a href={buildRoute()}
            {...additionalTabProps("inbox")}
                onClick={() => handleFolderChange('inbox')}
                className={'oms--folder-tab'
                    + (unreadMsgCount > 0 ? ' unread' : '')
                    + (folder === 'inbox' ? ' active' : '')}
                aria-label={dict.inboxFolder.a11y + (unreadMsgCount > 0 ? ` ${unreadMsgCount} unread messages` : '') } data-testid='oms-inbox-tab'>
              {dict.inboxFolder.label + (unreadMsgCount > 0 ? ` (${unreadMsgCount})` : '')}
            </a>
          </li>
          <li>
            <a href={buildRoute()}
              {...additionalTabProps("sent")}
                onClick={() => handleFolderChange('sent')}
                className={'oms--folder-tab' + 
                    (folder === 'sent' ? ' active' : '')}
                aria-label={dict.sentFolder.a11y} data-testid='oms-sent-tab'>
                {dict.sentFolder.label}
            </a>
          </li>
          <li>
            <a href={buildRoute()}
                {...additionalTabProps("archive")}
                onClick={() => handleFolderChange('archive')}
                className={'oms--folder-tab' + 
                    (folder === 'archive' ? ' active' : '')}
                aria-label={dict.archiveFolder.a11y} data-testid='oms-archive-tab'>
              {dict.archiveFolder.label}
            </a>
          </li>
          {allowDrafts && 
            <li>
                <a href={buildRoute()}
                    {...additionalTabProps("drafts")}
                    onClick={() => handleFolderChange('drafts')}
                    className={'oms--folder-tab' + 
                        (folder === 'drafts' ? ' active' : '')}
                    aria-label={dict.draftsFolder.a11y} data-testid='oms-drafts-tab'>
                {dict.draftsFolder.label}
                </a>
            </li>
          }
        </ul>
		 }
		</div>
    )
}

function FolderSelect() {
    const { dict } = useLang();
    const { folder, setFolder, setPage } = useView();
    const { allowDrafts } = useConfig();
    const [showingMenu, setShowingMenu] = useState(false);
    const [allItems, setAllItems] = useState([]);
    const { unreadMsgCount } = useThreads();

    useEffect(() => {
        let items = [
            {
              id: 'inbox',
              label: dict.inboxFolder.label,
              unreadCount: unreadMsgCount || 0
            },
            {
              id: 'sent',
              label: dict.sentFolder.label,
              unreadCount: 0
            },
            {
              id: 'archive',
              label: dict.archiveFolder.label,
              unreadCount: 0
            }
          ]
          if (allowDrafts) {
            items.push({
                id: 'drafts',
                label: dict.draftsFolder.label,
                unreadCount: 0
            })
          }
          setAllItems(items)
    }, []);

    const handleClick = (e) => {
        e.preventDefault()
        setShowingMenu(!showingMenu)
    }

    const getSelectedItem = (unreadCount) => {
        const selectedItem = allItems.find(item => item.id === folder)
        if (selectedItem) {
            return (
                <div
                    className={`oms--drop-button-label ${
                        selectedItem.id === 'inbox' && unreadCount > 0 ? ' oms--unread' : ''
                    }`}
                    name={selectedItem.id}
					          data-testid='oms-drop-folder-selected'
                >
                    {selectedItem.label +
                        (selectedItem.id === 'inbox' && unreadCount > 0
                        ? ' (' + unreadCount + ')'
                        : '')}
                </div>
            )
        }
        return null
    }

    const handleFolderChange = (folder) => {
        setFolder(folder);
        setPage(1);
        setShowingMenu(false);
    }

    const handleKeyDown = (event, folder, index) => {
        if (event.key === 'Enter') {
            handleFolderChange(folder);
        } else if (event.key === 'Tab') {
            if (index === allItems.length - 1) {
              setShowingMenu(false);
            }
        }        
    }

    const getMenuItems = () => {
        let menuItems = []
        allItems.forEach((item, index) => {
          if (item.id !== folder) {
            let label = item.label
            // if this is the inbox, add unread message count and dot
            if (item.id === 'inbox' && unreadMsgCount > 0) {
              label += ' (' + unreadMsgCount + ')'
            }
            menuItems.push(
              <li key={item.id} tabIndex="0"
                onClick={() => handleFolderChange(item.id)}
                onKeyDown={(event) => handleKeyDown(event, item.id, index)}>
                  <a
                    className={`oms--menu-item ${
                    item.id === 'inbox' && unreadMsgCount > 0 ? ' oms--unread' : ''
                    }`}
                    aria-label={dict[item.id + 'Folder'].a11y}
                    data-testid={'oms-'+ item.id +'-folder-option'}
                >
                    {item.id === 'inbox' && unreadMsgCount > 0 && <ScreenReaderOnly message={'unread messages'} />}
                    {label}
                </a>
              </li>
            )
          }
        })
        return menuItems
    }

  return (
    <div className='oms--dropdown' data-testid='oms-folder-select'>
      <button onClick={handleClick} 
        data-testid='oms-drop-button'
        aria-expanded={showingMenu ? 'true' : 'false'}
        aria-controls='oms--menu'>
        <div className='oms--drop-button-content-wrapper'>
          {getSelectedItem(unreadMsgCount)}
          <div
            className={
              'oms--drop-button-icon' +
              (showingMenu ? ' oms--showing' : ' oms--closed')
            }
          >
            <span className='sm-icon sm-icon--16 icon--arrow_up'></span>
          </div>
        </div>
      </button>
      <ul id='oms--menu' className={'oms--menu' + (showingMenu ? ' oms--showing' : '')}>
        {getMenuItems()}
      </ul>
    </div>
  )
}

export default Folders;
