import React, { useState } from 'react';
import classnames from 'classnames';
import { useAttachment } from '@context/attachment';

import ScreenReaderOnly from '../ScreenReaderOnly'

import './FileList.scss';

const GetAttachment = (props) => {
	const { file: { attachment_id, name = 'placeholder_name', size }, errors } = props;
	const [isDownloading, setIsDownloading] = useState(false);
	const [progress, setProgress] = useState(0);
  const attachment = useAttachment();
	const percentage = Math.floor(progress * 100);
	
	const onDownloadProgress = ({ loaded, total, lengthComputable }) => {
		
		let totalSize = total;
		if (!lengthComputable) {
			totalSize = size
		};
		setProgress(loaded / totalSize);
	}
	const resetState = () => {
		setProgress(0);
		setIsDownloading(false);
	}
	const handleDownload = async () => {
		// if (isDownloading) return;

		try {
		  setIsDownloading(true);
		  await attachment.downloadAttachment(attachment_id, name, errors, { onDownloadProgress })
		} finally {
		  resetState()
		}
	}
	return (
      <span
        className={classnames('oms--get-attachment', {
          'oms--is-downloading': isDownloading
        })}
        data-testid="oms--get-attachment"
        data-download-progress={percentage}
      >
        {attachment_id && (
          <button
            className='oms--download-link'
            disabled={isDownloading}
            onClick={handleDownload}
            title={`Download ${name}`}
          >
            {!isDownloading ? (
			        <span className="sm-icon icon--download" />
            ) : (
              <div className="loader" />
            )}
            <ScreenReaderOnly message={`Download ${name} `} />
          </button>
        )}
        {isDownloading && (
          <span
            className='oms--download-progress'
            role='status'
            aria-relevant='text'
            aria-atomic='true'
            aria-live='polite'
            data-download-progress={percentage}
          >
            {percentage}%
          </span>
        )}
      </span>
    );
};

export default GetAttachment;
