import React, { useEffect, useState } from 'react';
import './filters.scss';
import DatePicker from './DatePicker';
import TopicPicker from './TopicPicker';
import { useThreadFilter } from '@context/threadFilter';
import { useLang } from '@context/lang';
import { useView } from '@context/view';
import SplitButton from '../SplitButton';

function Filters() {
    const { dict } = useLang();
    const { startDate, setStartDate, endDate, setEndDate, tags, setTags, setRefreshThreads } = useThreadFilter();
    const { folder } = useView();
    const [showTopicPicker, setShowTopicPicker] = useState(false);
    const [showStartDatePicker, setShowStartDatePicker] = useState(false);
    const [showEndDatePicker, setShowEndDatePicker] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setShowTopicPicker(false);
        setShowStartDatePicker(false);
        setShowEndDatePicker(false);
    }, [startDate, endDate, tags])

    useEffect(() => {
        setDisabled(folder === 'drafts');
    }, [folder])

    const toggleTopicPicker = (evt) => {
        if (!disabled) {
            if (!showTopicPicker) {
                setShowStartDatePicker(false);
                setShowEndDatePicker(false);
            }
            setShowTopicPicker(!showTopicPicker);
        }
        evt.stopPropagation();
        evt.preventDefault();
    }

    const toggleStartDatePicker = (evt) => {
        if (!disabled) {
            if (!showStartDatePicker) {
                setShowTopicPicker(false);
                setShowEndDatePicker(false);
            }
            setShowStartDatePicker(!showStartDatePicker);
        }
        evt.stopPropagation();
        evt.preventDefault();
    }

    const toggleEndDatePicker = (evt) => {
        if (!disabled) {
            if (!showEndDatePicker) {
                setShowTopicPicker(false);
                setShowStartDatePicker(false);
            }
            setShowEndDatePicker(!showEndDatePicker);
        }
        evt.stopPropagation();
        evt.preventDefault();
    }

    const filterThreadsHandler = (evt) => {
        setShowTopicPicker(false);
        setShowStartDatePicker(false);
        setShowEndDatePicker(false);
        setRefreshThreads(true);
        evt.stopPropagation();
        evt.preventDefault();
    }

    const clearFilterHandler = (event) => {
        setShowTopicPicker(false);
        setShowStartDatePicker(false);
        setShowEndDatePicker(false);
        setStartDate(null);
        setEndDate(null);
        setTags([]);
        setRefreshThreads(true);
        evt.stopPropagation();
        evt.preventDefault();
    }

    const keyDownToggleTopicPicker = (event) => {
        let isEnterPressed = event.key === 'Enter';
        if (isEnterPressed) {
            toggleTopicPicker(event);
        }
    }

    const keyDownToggleStartDatePicker = (event) => {
        let isEnterPressed = event.key === 'Enter';
        if (isEnterPressed) {
            toggleStartDatePicker(event);
        }
    }

    const keyDownToggleEndDatePicker = (event) => {
        let isEnterPressed = event.key === 'Enter';
        if (isEnterPressed) {
            toggleEndDatePicker(event);
        }
    }

    return (
        <div className={'oms--filters-container' + (disabled ? ' disabled' : '')}>
            <div className={'oms--filters-pill'}>
                <div className='oms--filter-outer'>
                    <div className='oms--filter' role='button' onClick={toggleTopicPicker} onKeyDown={keyDownToggleTopicPicker} tabIndex='0'>
                        <label title={tags.map(elem => elem.label).join()}>{dict.filters.topic}</label>
                        <div className='oms--filter-value'>
                            {tags.length === 0 ? dict.filters.all : tags.map(elem => elem.label).join()}
                        </div>
                        <TopicPicker show={showTopicPicker} onHide={() => setShowTopicPicker(false)} />
                    </div>
                </div>
                <div className='oms--filter-outer'>
                    <div className='oms--filter' role='button' onClick={toggleStartDatePicker} onKeyDown={keyDownToggleStartDatePicker} tabIndex='0'>
                        <label title={startDate}>{dict.filters.updatedAfter}</label>
                        <div className='oms--filter-value'>
                            {startDate === null ? dict.filters.addDate : startDate}
                        </div>
                        <DatePicker 
                            show={showStartDatePicker} 
                            isStartDate={true}
                            startDate={startDate}
                            endDate={endDate}
                            setSelectedDate={(d) => setStartDate(d)}
                            onHide={() => setShowStartDatePicker(false)} />
                    </div>
                </div>
                <div className='oms--filter-outer'>
                    <div className='oms--filter' role='button' onClick={toggleEndDatePicker} onKeyDown={keyDownToggleEndDatePicker} tabIndex='0'>
                        <label title={endDate}>{dict.filters.updatedBefore}</label>
                        <div className='oms--filter-value'>
                            {endDate === null ? dict.filters.addDate : endDate}
                        </div>
                        <DatePicker 
                            show={showEndDatePicker} 
                            isEndDate={true}
                            startDate={startDate}
                            endDate={endDate}
                            setSelectedDate={(d) => setEndDate(d)}
                            onHide={() => setShowEndDatePicker(false)} />
                    </div>
                </div>
                <div className='oms--filter-outer'>
                    <SplitButton>
                        <button onClick={filterThreadsHandler} disabled={disabled}>
                            {dict.filters.filter}
                        </button>
                        <button onClick={clearFilterHandler} disabled={disabled}>
                            {dict.filters.clear}
                        </button>
                    </SplitButton>
                </div>
            </div>
        </div>
    )
}

export default Filters;