import React, { useEffect, useState } from 'react';
import { useView } from './view';
import { useErrors } from './errors';
import { useLang } from './lang';
import axios from '@util/axios_config';
import { useAnalytics } from './analytics';
import { useConfig } from './config';
import { useThreadFilter } from './threadFilter';

const ThreadsContext = React.createContext();

const THREADSPERPAGE = 10;

function ThreadsProvider({children}) {
    const { folder } = useView();
    const errors = useErrors();
    const { dict } = useLang();
    const { viewStart, viewEnd } = useAnalytics();
    const { token } = useConfig();
    const { startDate, endDate, tags, refreshThreads, setRefreshThreads } = useThreadFilter();
    const [threads, setThreads] = useState([]);
    const [numPages, setNumPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [unreadMsgCount, setUnreadMsgCount] = useState(0);
    
    useEffect(() => {
        setThreads([]);
        if (folder !== 'drafts') {
            loadThreads();
        }
    }, [folder])

    useEffect(() => {
        if (refreshThreads) {
            loadThreads();
            setRefreshThreads(false);
        }
    }, [refreshThreads])

    useEffect(() => {
        if (unreadMsgCount >= 0) {
            // fire a custom even for the global nav
            let evt = new CustomEvent('unreadMessageCount', {
                detail: { unreadMessageCount: unreadMsgCount }
            })
            document.body.dispatchEvent(evt)
        }
    }, [unreadMsgCount])

    const loadThreads = async () => {
        viewStart(folder);
        setIsLoading(true);
        try {
            let config = {
                params: { count: 250, folder_type: folder }
            }
            if (startDate) {
                config.params.updated_at_gt = startDate;
            }
            if (endDate) {
                config.params.updated_at_lt = endDate;
            }
            if (tags.length > 0) {
                config.params.tags = tags.map(t => t.id).join();
            }
            if (token) {
                config.headers = { Authorization: `Bearer ${token}` };
            }
            let response = await axios.get('threads', config);
            if (folder === 'inbox') {
                let unreadCount = 0;
                response.data.thread.map(thread => {
                    unreadCount = unreadCount + thread.unread_message_count;
                })
                setUnreadMsgCount(unreadCount);
            }
            const msgThreads = response.data.thread;
            setThreads(msgThreads);
            setNumPages(Math.ceil(msgThreads.length / THREADSPERPAGE));
        } catch (e) {
            errors.add('threads:failure', dict.errMessageLoading.message, e, 'Threads Load failure')
        } finally{
            setIsLoading(false);
            viewEnd(folder);
        }
    }
	
    const setUnreadCount = (threadId) => {
        const thread = threads.find(t => t.thread_id === threadId);
        if (thread && thread.unread_message_count > 0) {
            // get the index of the thread in the threads array
            const threadIndex = threads.findIndex(t => t.thread_id === threadId);
            // create a new thread locally with unread_message_count set to 0            
            const newThread = {...thread, unread_message_count: 0};
            // replace the thread in the threads array with the new thread
            setThreads(threads.toSpliced(threadIndex, 1, newThread));
            // update the unreadMsgCount
            setUnreadMsgCount(unreadMsgCount - thread.unread_message_count);
        }
    }

    const provider = {
        threads,
        numPages,
        isLoading,
        loadThreads,
        unreadMsgCount,
        setUnreadCount,
    }

    return <ThreadsContext.Provider value={provider}>{children}</ThreadsContext.Provider>
}

function useThreads() {
    const context = React.useContext(ThreadsContext);
    if (!context) {
        throw new Error('useThreads can only be used within a ThreadsContext provider');
    }
    return context;
}

export { ThreadsProvider, useThreads ,ThreadsContext}
