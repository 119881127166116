import React from 'react';
import EmphasisButton from './EmphasisButton';
import { useLang } from '@context/lang';
import { useMessageDraft } from '../../../context/messageDraft';

function SendButton ({
  ...props
}) {
    const { dict } = useLang();
    const { isSending, isUploadingAttachments } = useMessageDraft();

  if (isSending) {
    return (
      <EmphasisButton {...props} >
        {dict.sendingLabel.label}
      </EmphasisButton>
    )
  }
  
  const isDisabled = isUploadingAttachments
  return (
    <EmphasisButton {...props} disabled={isDisabled} >
      {dict.sendLabel.label}
    </EmphasisButton>
  )
}

export default SendButton;
