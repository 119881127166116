import React, { useEffect, useState } from 'react';
import axios from '@util/axios_config';
import { useAnalytics } from './analytics';
import { useConfig } from './config';

const ComposeContext = React.createContext(null);

function ComposeProvider({children}) {
    const { token } = useConfig();
    const { publish } = useAnalytics();
    const [formData, setFormData] = useState(null);
    const [status, setStatus] = useState('loading');

    useEffect(() => {
        loadSecureMessageData();
    }, []);

    const loadSecureMessageData = async () => {
        try {
            let config = {};
            if (token) {
                config.headers = {Authorization: `Bearer ${token}`};
            }
            const { contentUrl, portal, language } = axios;
            let response = await axios.post(contentUrl, {
              portal,
              language,
              components: ['secureMessage']
            }, config)
            setFormData(response.data.content.secureMessage)
            setStatus('loaded')
          } catch (e) {
            setStatus('error')
            // publish the error to the analytics store
            publish(
                { resource: 'error' },
                { error: e, type: 'Compose content load failure' }
            )
          } finally {
            
          }
    }

    const provider = {
        formData,
        status,
    }

    return <ComposeContext.Provider value={provider}>{children}</ComposeContext.Provider>
}

function useCompose() {
    const context = React.useContext(ComposeContext);
    if (!context) {
        throw new Error('useCompose must be used within a ComposeContext provider');
    }
    return context;
}

export {ComposeProvider, useCompose};