import React,{useEffect, useState} from 'react';
import { useView } from '@context/view';
import { useLang } from '@context/lang';
import {useThreads} from '@context/threads'
import './Threads.scss';
import { useMessages } from '@context/messages';
import DateFormatter from  '../../../util/date_formatter';
import Loader from '../Loaders/loader';
import ScreenReaderOnly from '../ScreenReaderOnly';

function Threads() {
    const { thread, page } = useView();
    const { dict } = useLang();
    const { isLoading, threads } = useThreads();

    return (
      <Loader loading={isLoading} >
        <main className='oms--threads'>
            {threads.length === 0 && <div>{dict.noMessages.message}</div>}
            {threads.slice((page-1)*10, ((page-1)*10)+10)
              .map((trd, idx) => <Thread key={idx} thread={trd} highlight={thread && thread.thread_id === trd.thread_id}/>)}
        </main>
        </Loader>
    )
}

function Thread ({ thread, highlight }) {
    const { setThread, setThreadTitle, folder } = useView();
    const { getMessages } = useMessages();
	const [unread,setUnread] = useState(0);
	
    const {
      thread_id,
      who,
      subject,
      date_updated,
      summary,
      sender_display_name
    } = thread
    // Hard to style a component with the exact same class. Or maybe .class1.class2?
    let name_to_display = sender_display_name == '' ? who : sender_display_name
  
    useEffect(() => {
        setUnread(thread.unread_message_count);
    }, [thread])

    const threadClickHandler = (e,threadId) => {
        e.preventDefault();
        setThread(thread);
        setThreadTitle(subject);
        getMessages(threadId);
	    if(unread) {
			  setUnread(unread-1);
		  }		
    }

    return (
      <article
        className={'oms--thread-bar ' + (highlight && 'oms--highlight')}
        aria-label={`click to view messages`}
		data-testid='oms-thread-bar'
		onClick={(e) => threadClickHandler(e,thread_id)}
      >
        <a href='#'
          tabIndex='0'
          aria-label={(unread ? 'unread, ' : '') + `Subject: ${subject} ${summary}`}
          className='oms--thread'          
		   data-testid='oms-thread'
        >
          <div className='oms--message-info'>
            <div className='oms--outer-container'>
              
                <div
                  className={'oms--message-unread-indicator' + (unread > 0  ? ' unread' : '')}
                ><ScreenReaderOnly message='this thread has unread messages' /></div>
              
              <div
                className={'oms--message-info-primary' + (unread > 0  ? ' unread' : '')}
              >
                <div className='oms--subject-line'>
                  <div className='oms--name'>
                    {folder === 'drafts' && <span className='oms--draft-label'>Draft:</span>}
                    {name_to_display}
                    <AttachmentDisplay thread={thread} />
                  </div>
                  <DateContainer
                    date={date_updated}
                    className='oms--date-updated'
                    format='thread:list'
                  />
                </div>
                <div className='oms--subject-line'>
                  <div className='oms--subject'>
                    <div dangerouslySetInnerHTML={{ __html: subject }} />
                  </div>
                </div>
                <div
                  className={
                    'oms--summary ' +
                    (findLongestWord(summary) > 50 ? 'oms--break-long-word' : '')
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: summary
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </a>
      </article>
    )
}

function AttachmentDisplay ({
  thread: { attachment_count = 0 } = {},
  hasAttachments = attachment_count > 0
}) {
  if (hasAttachments) {
    return (
      <div className='oms--attachment-icon'>
	    <span className="sm-icon icon--attachment" />
      </div>
    )
  }
  return null
}

function findLongestWord (str) {
    const longestWord = str.split(' ').sort(function (a, b) {
        return b.length - a.length
    })
    return longestWord[0].length
}

function DateContainer (props) {
  
  return (
   <time className='oms--date-updated' dateTime=''><DateFormatter date={props.date} formatIdentifier={props.format} /></time>
  )
}

export default Threads;
