import React, { useState } from 'react';
import { useLang } from '@context/lang';
import { useErrors } from '@context/errors';
import GetAttachment from './GetAttachment';
import { formatSize } from '@util/helper';
import classnames from 'classnames';
import ScreenReaderOnly from '../ScreenReaderOnly';

import './FileList.scss';

const File = (props) => {
	const [ hover, setHover ] = useState(false);
	const errors = useErrors();
	const { file, cancelUpload, removeFile, mailboxID } = props;
	const lang = useLang();
    let file_size = 0
    if (file.size) {
      // separate number and unit with &nbsp;
      file_size = formatSize(file.size)
    }
	
	const setHoverEffect = enable => {
      setHover(enable);
    }
	return (
		<li className='oms--file-list-item'>
        <div className='oms--file-information'>
          <div className='oms--file-icon'>
            {mailboxID ? (
              <span className="sm-icon icon--document" />
            ) : removeFile ? (
              <span className="sm-icon icon--checkmark_filled" />
               
            ) : (
              <div className="loader" />
            )}
          </div>
          <div className='oms--file-details'>
            <abbr
              className={classnames({
                'oms--tool-tip': true,
                'oms--file-name': true,
                hover
              })}
              data-testid="file-name"
              title={file.name}
              aria-label={file.name}
            >
              {file.name}
            </abbr>
            <span data-testid="oms--file-size" className='oms--file-size'>{file_size}</span>
          </div>
          {mailboxID ? (
            <div className='oms--get-file'>
              <GetAttachment
                file={file}
                mailboxID={mailboxID}
                errors={errors}
              />
            </div>
          ) : null}
        </div>
        {removeFile || cancelUpload ? (
          <div className='oms--file-actions'>
            {removeFile && (
              <button
                className='oms--file-action'
                onClick={() => {
                  removeFile(file)
                }}
                onMouseEnter={() => setHoverEffect(true)}
                onMouseLeave={() => setHoverEffect(false)}
                type='button'
                aria-label={lang.dict.removeAttachment.a11y.replace('{fileName}', file.name)}
              >
                <div className='oms--file-action-icon'>
                  <span className="sm-icon icon--trash_delete" />
                  <ScreenReaderOnly
                    message={lang.dict.removeAttachment.a11y.replace('{fileName}', file.name)}
                  />
                </div>
                <div className='oms--file-action-label'>
                  {lang.dict.removeAttachment.label}
                </div>
              </button>
            )}
            {cancelUpload && (
              <button
                className='oms--file-action'
                onClick={() => {
                  cancelUpload(file)
                }}
                data-testid="cancel-upload-button"
                type='button'
                aria-label={lang.dict.cancelUploading.a11y.replace('{fileName}', file.name)}
              >
                <div className='oms--file-action-icon'>
                  <span className="sm-icon icon--remove" />
                  <ScreenReaderOnly
                    message={lang.dict.cancelUploading.a11y.replace('{fileName}', file.name)}
                  />
                </div>
                <div className='oms--file-action-label'>
                  {lang.dict.cancelUploading.label}
                </div>
              </button>
            )}
          </div>
        ) : null}
      </li>
	);
}

export default File;
