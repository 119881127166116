import React, { useEffect } from 'react'
import { useLang } from '@context/lang';
import './notification.scss'
import ScreenReaderOnly from '../ScreenReaderOnly';
import { useView } from '@context/view';

function Notification() {
    const { notificationMessage, setNotificationMessage } = useView();
    const { dict } = useLang();

  useEffect(() => {
    if (notificationMessage) {
        if (notificationMessage.length > 0) {
            setTimeout(() => {
                setNotificationMessage('');
            }, 6000);
        }
    }
  }, [notificationMessage]);

  const dismissNotification = () => {
    setNotificationMessage('');
  }

  return (
      <>
      {notificationMessage !== '' ? 
        <div aria-label='Notification area' className='oms--notifications-area'>
            <div className="oms--list-container">
                <ul aria-live='assertive' className='oms--list'>
                    <span className='oms--item' role='alert' aria-live='polite'>
                        <span className='oms--error-details'>
                            <span className="sm-icon check-circle-icon icon--checkmark_filled" />
                            <span className='oms--notification-text'>
                                {notificationMessage}
                            </span>
                        </span>
                    </span>
                </ul>
                <button
                    type="button"
                    className='oms--tertiary'
                    data-testid='notification-close'
                    onClick={dismissNotification}
                >
                        <ScreenReaderOnly
                        message={`Close 1 notifications`}
                    />
                    <span className='oms--dismiss-text'>
                        {dict.closeLabel.label}
                    </span>
                </button>
            </div>
        </div>
    : null}
    </>
  )
}

export default Notification;