import React, { forwardRef } from 'react';

const Date = forwardRef(({id, hasError, errorMessage, value, onChangeHandler, onBlurHandler, tabIndex, field}, ref) => {
    return (
        <input 
            type='text' 
            ref={ref}
            id={id}
            className={errorMessage ? ' oms--red-ring' : ''}
            value={value} 
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            tabIndex={tabIndex}
            aria-label={field.label}
            aria-required={field.required || false}
            aria-describedby={hasError ? id + '_error' : null}
        />	
    )      
})
export default Date;
    