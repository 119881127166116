import React from 'react';
import File from './File';

import './FileList.scss';

const FileList = (props) => {
	const { files, mailboxID, removeFile, cancelUpload } = props;
    return (
	  <ul data-testid="file-list" className='oms--file-list'>
	{files.map((file, i) => (<File 
			  file={file}
			  mailboxID={mailboxID}
			  key={file.attachment_id || file.id || `${i}${file.name}${file.type}`}
			  removeFile={removeFile}
			  cancelUpload={cancelUpload}
			  name={file.name} 
			/>))}
	 </ul>  
    )
}

export default FileList;
