import axios from 'axios'

let instance

instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Source': 'ogn-secure-message-ui'
    // AuthManager will add an auth header if it succeeds in authenticating
  },
  baseURL: '/api/', // unless absolute path given, and init can override this
  portalName: '',
  language: 'en'
})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

export default instance