import React from 'react';

import './ContactDisplay.scss'

const ContactDisplay = ({
    name,
    display_name,
    recipient_type,
    onReply
}) => {
    if (onReply == 'true') {
        var name_to_display = recipient_type == 'to' ? (display_name === undefined || display_name == '') ? name : display_name : name
      } else {
        var name_to_display =
          recipient_type == 'to'
            ? name
            : display_name === undefined || display_name == ''
            ? name
            : display_name
      }
      return (
        <div className='oms--contact' title={`name: ${name_to_display}`}>
          <span className='oms--name'>{name_to_display}</span>
        </div>
      )
}

export default ContactDisplay;
