import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import './DatePicker.scss';
import { useLang } from '@context/lang';

function DatePicker({show, isStartDate = false, isEndDate = false, startDate = null, endDate = null, setSelectedDate, onHide}) {
    const { dict } = useLang();
    const [pickedDate, setPickedDate] = useState(moment().format('YYYY-MM-DD'));
    const [weeks, setWeeks] = useState([]);
    const beginingOfTime = '2024-02-19';
    const endOfTime = moment().format('YYYY-MM-DD');
    const prevMonthButtonRef = useRef(null);
    const nextMonthButtonRef = useRef(null);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (show) {
            if (prevMonthButtonRef.current) {
                prevMonthButtonRef.current.focus();
            } else {
                nextMonthButtonRef.current.focus();
            }
        }
    }, [show])

    useEffect(() => {
        renderWeeks();
    }, [startDate, endDate])

    useEffect(() => {
        renderWeeks();
    }, [pickedDate])

    const prevMonthHandler = (event) => {
        setPickedDate(moment(pickedDate).subtract(1, 'months').format('YYYY-MM-DD'));
        event.stopPropagation();
        event.preventDefault();
    }

    const nextMonthHandler = (event) => {
        setPickedDate(moment(pickedDate).add(1, 'months').format('YYYY-MM-DD'));
        event.stopPropagation();
        event.preventDefault();
    }

    const clearSelectedDateHandler = (event) => {
        setSelectedDate(null);
        event.stopPropagation();
        event.preventDefault();
    } 

    const keyDownResetHandler = (event) => {
        let isEnterPressed = event.key === 'Enter';
        if (isEnterPressed) {
            clearSelectedDateHandler(event);
        }
    }

    const setSelectedDateHandler = (event, dayOfMonth) => {
        setSelectedDate(moment(pickedDate).date(dayOfMonth).format('YYYY-MM-DD'))
        event.stopPropagation();
        event.preventDefault();
    }

    const keyDownSelectDateHandler = (event, dayOfMonth) => {
        let isEnterPressed = event.key === 'Enter';
        if (isEnterPressed) {
            setSelectedDateHandler(event, dayOfMonth);
        }
    }

    const renderWeeks = () => {
        let days = [], _weeks = [];
        const month = moment(pickedDate).date(1);
        // create the days leading up to the start of the month
        for (let i = 0; i < month.day(); i++) {
            days.push(<td key={'_' + i}></td>);
        }
        // create the days in the month
        const daysInMonth = month.daysInMonth();
        for (let j = 1; j <= daysInMonth; j++) {
            const theDate = moment(pickedDate).date(j);
            const isBeforeBeginingOfTime = theDate.isBefore(moment(beginingOfTime));
            const isBeforeStartDate = theDate.isBefore(startDate) && !isStartDate;
            const isAfterEndDate = theDate.isAfter(endDate) && !isEndDate;
            const isAfterEndOfTime = theDate.isAfter(endOfTime);
            const isSelected = theDate.isSame(startDate) || theDate.isSame(endDate);

            if (isBeforeBeginingOfTime || isBeforeStartDate || isAfterEndDate || isAfterEndOfTime) {
                days.push(
                    <td key={'d' + j}>
                        <div className='oms--day-picker-day'>{j}</div>
                    </td>
                )
            } else if (isSelected) {
                days.push(
                    <td key={'d' + j}>
                        <div className='oms--day-picker-day selected'>{j}</div>
                    </td>
                )
            } else {
                days.push(
                    <td key={'d' + j}>
                        <div role='button'
                            tabIndex={0} 
                            className='oms--day-picker-day available' 
                            onClick={event => setSelectedDateHandler(event, j)}
                            onKeyDown={event => keyDownSelectDateHandler(event, j)}>{j}</div>
                    </td>
                )
            }
            if (days.length === 7 || j === daysInMonth) {
                _weeks.push(<>{days.map(d => {return d})}</>);
                days = [];
            }
        }
        setWeeks(_weeks);
    }

    const monthButtonKeyDownHandler = (event) => {
        let isTabPressed = event.key === 'Tab' || event.keyCode === 9;

        if (isTabPressed && event.shiftKey) {
            if (prevMonthButtonRef?.current) {
                if (event.target === prevMonthButtonRef.current) {
                    closeButtonRef.current.focus();
                } else {
                    prevMonthButtonRef.current.focus();
                }
            } else {
                closeButtonRef.current.focus();
            }
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const closeKeyDownHandler = (event) => {
        let isTabPressed = event.key === 'Tab' || event.keyCode === 9;

        if (isTabPressed && !event.shiftKey) {
            if (prevMonthButtonRef?.current) {
                prevMonthButtonRef.current.focus();
            } else {
                nextMonthButtonRef.current.focus();
            }
            event.stopPropagation();
            event.preventDefault();
        }
    }

    return (
        <div className={'oms--date-picker-dialog' + (show ? ' show' : '')}>
            <div className='oms--date-picker-container'>
                <div className='oms-date-picker-month-picker'>
                    {moment(pickedDate).isAfter(beginingOfTime) ?
                    <button onClick={prevMonthHandler} ref={prevMonthButtonRef} onKeyDown={monthButtonKeyDownHandler}>
                        <span className='sm-icon sm-icon--16 icon--arrow_wtail_left'></span>
                    </button>
                    : <div style={{width:'28px'}}/>
                    }
                    <div className='oms--date-picker-month-year'>{moment(pickedDate).format('MMMM, YYYY')}</div>
                    <button onClick={nextMonthHandler} ref={nextMonthButtonRef} onKeyDown={monthButtonKeyDownHandler}>
                        <span className='sm-icon sm-icon--16 icon--arrow_wtail_right'></span>
                    </button>
                </div>
                <table>
                    <thead>
                        <tr><th>S</th><th>M</th><th>T</th><th>W</th><th>T</th><th>F</th><th>S</th></tr>
                    </thead>
                    <tbody>
                        {weeks.map((w, i) => <tr key={'w' + i}>{w}</tr>)}
                    </tbody>
                </table>
                <div className='oms--filter-footer'>
                    <button onClick={clearSelectedDateHandler} onKeyDown={keyDownResetHandler}>{dict.filters.reset}</button>
                    <button 
                        className='oms--filter-close' 
                        onClick={onHide}
                        onKeyDown={closeKeyDownHandler}
                        ref={closeButtonRef}>{dict.filters.close}</button>
                </div>
            </div>
        </div>
    )
}

export default DatePicker;