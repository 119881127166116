import React, { forwardRef } from 'react';

const parseInputForEncoding = (string = '') => {
    var new_str = string
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>')
        .replace(/&amp;/gi, '&')
    return new_str
}

const Input = forwardRef(
    ({ id, hasError, errorMessage, onChangeHandler, value, onBlurHandler, tabIndex, field }, ref) => {

    let maxLength = null
    if (field.maxLength) {
        maxLength = field.maxLength
    }
    
    let ariaLabel = field.label;
    let ariaDescribedBy = null;
    if (field.hint) {
        ariaLabel += `. ${field.hint}`
        ariaDescribedBy = `hint_message_${id}`
    }

    if (hasError) {
        ariaDescribedBy = id + '_error';
    }
    
    return (
        <React.Fragment>
            <input
                ref={ref}
                type='text'
                id={id}
                className={errorMessage ? ' oms--red-ring' : ''}
                value={value}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                maxLength={maxLength}
                tabIndex={tabIndex}
                aria-label={ariaLabel}
                aria-required={field.required || false}
                aria-describedby={ariaDescribedBy}
            />
            {maxLength && (
                <div>
                    {parseInputForEncoding(value).length} of {maxLength}
                </div>
            )}
        </React.Fragment>
    )
})
        
export default Input;
        