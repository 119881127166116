import React, {useState, useRef} from 'react'
import ReactTooltip from 'react-tooltip'
import './FieldTip.scss'
import { useConfig } from '@context/config'

function FieldTip(props) {
    const { isMobile } = useConfig();
    const [isShowing, setIsShowing] = useState(false);
    const btnRef = useRef(null);
    
    const toggleTooltip = () => {
        if (isShowing) {
            ReactTooltip.hide(btnRef.current)
        } else {
            ReactTooltip.show(btnRef.current)
        }
    }
    const toggleShowingState = () => {
        setIsShowing(!isShowing);
    }
    
    const getTipId = () => {
        // we need to have an id that is unique
        return props.id + '_tip'
    }
    
    return (
        <button
            data-tip
            data-for={getTipId()}
            className='oms--field-tip'
            ref={btnRef}
            onClick={toggleTooltip}
            tabIndex={props.tabIndex}
            aria-label={isShowing ? props.tooltip.content : props.tooltip.title + ' click for more information'}
        >
            <span className='sm-icon icon--info-hollow'/>
            {props.tooltip.title}
            <ReactTooltip
                id={getTipId()}
                place={isMobile ? 'top' : 'right'}
                type='light'
                effect='solid'
                border={true}
                borderColor='#ddd'
                className='oms--field-tooltip'
                afterShow={toggleShowingState}
                afterHide={toggleShowingState}
            >
                {props.tooltip.content}
            </ReactTooltip>
        </button>
    )
}
    
export default FieldTip
    