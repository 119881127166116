import React from 'react';
import './Radio.scss';

function Radio( {id, hasError, field, value, onChangeHandler, tabIndex}) {
    const optionChangeHandler = (_, optionValue) => {
        onChangeHandler(optionValue);
    }
    
    return (
        <div id={id} className='oms--radio-group'>
        {
            field.options.map((opt, idx) => {
                return (
                    <button 
                        className='oms--radio' 
                        key={idx} 
                        type='button' 
                        tabIndex={tabIndex} 
                        onClick={(e) => optionChangeHandler(e, opt.text)}
                        aria-describedby={hasError ? id + '_error' : null}>
                        <label>
                            <span data-testid={opt.text} className={`sm-icon icon--${value === opt.text ? 'radio_button_filled' : 'radio_button_empty'}`} />
                            {opt.text}
                        </label>
                    </button>
                    )
                })
                
        }
        </div>
    );        
            
}
        
export default Radio;
        