import React, { forwardRef } from 'react';
import { FaBold, FaItalic, FaUnderline, FaListUl, FaListOl, FaIndent, FaOutdent } from 'react-icons/fa'; 
import './OptumEditor.scss';

const OptumEditor = forwardRef(({id, tabIndex, value, onChangeHandler, onKeyUp}, ref) => {
    const editorTabIndex = tabIndex ? tabIndex : '0';
    const divRef = React.useRef(null);
    const valueRef = React.useRef(value);
    const [isBold, setIsBold] = React.useState(false);
    const [isItalic, setIsItalic] = React.useState(false);
    const [isUnderline, setIsUnderline] = React.useState(false);
    
    const handleChange = (e) => {
        const html = divRef.current.innerHTML;
        onChangeHandler(html);
    }

    const handleKeyUp = (e) => {
        if (onKeyUp) onKeyUp(e);
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        // replace carriage returns with newlines
        // we do this here so the html that is generated will convert the newlines to <br> tags
        const cleanCarriageReturns = text.replace(/\r\n/g, '\n');
        document.execCommand('insertText', false, cleanCarriageReturns);
    }

    const performAction = (command) => {
        divRef.current.focus();
        document.execCommand(command, false, null);
        if (command === 'bold') {
            setIsBold(!isBold);
        }
        if (command === 'italic') {
            setIsItalic(!isItalic);
        }
        if (command === 'underline') {
            setIsUnderline(!isUnderline);
        }
        divRef.current.focus();
    }

    return (
        <div className='oms--optum-editor' ref={ref}>
            <div className='oms--editor-toolbar'>
                <button className={isBold ? 'selected' : ''} onClick={() => performAction('bold')} title='bold' data-testid='boldBtn'><FaBold /></button>
                <button className={isItalic ? 'selected' : ''} onClick={() => performAction('italic')} title='italic' data-testid='italicBtn'><FaItalic /></button>
                <button className={isUnderline ? 'selected' : ''} onClick={() => performAction('underline')} title='underline' data-testid='underlineBtn'><FaUnderline /></button>
                <button onClick={() => performAction('insertOrderedList')} title='ordered list' data-testid='orderedListBtn'><FaListOl /></button>
                <button onClick={() => performAction('insertUnorderedList')} title='unordered list' data-testid='unorderedListBtn'><FaListUl /></button>
                <button onClick={() => performAction('indent')} title='increase indent' data-testid='indentBtn'><FaIndent /></button>
                <button onClick={() => performAction('outdent')} title='decrease indent' data-testid='outdentBtn'><FaOutdent /></button>
                
            </div>
            <div className='oms--editable-div-outer'>
                <div className='oms--editable-div'
                    aria-required='true'
                    data-testid='optum-editor'
                    id={id}
                    tabIndex={editorTabIndex}
                    contentEditable
                    role='textarea'
                    onInput={handleChange}
                    onBlur={handleChange}
                    onKeyUp={handleKeyUp}
                    onPaste={handlePaste}
                    ref={divRef}
                    dangerouslySetInnerHTML={{__html: valueRef.current}}
                ></div>
            </div>
        </div>
    );
})

export default OptumEditor;