import React, { useState, useEffect } from 'react';
import en from './en.json';
import { useConfig } from './config';
import axios from '@util/axios_config';
import { useAnalytics } from './analytics';

const LangContext = React.createContext({
    dict: en,
    status: 'loaded'
});

function LangProvider({children}) {
    const { language } = useConfig();
    const { publish } = useAnalytics();
    const { token } = useConfig();
    const [lang, setLang] = useState('en');
    const [dictionary, setDictionary] = useState(en);
    const [status, setStatus] = useState('loading')

    useEffect(() => {
        setLang(language);
        fetch();
    }, [language]);

    const fetch = async () => {
        try {
            let config = {};
            if (token) {
                config.headers = {Authorization: `Bearer ${token}`};
            }
            const { contentUrl, portal, language } = axios
            let response = await axios.post(contentUrl, {
                portal,
                language,
                components: ['threadsView']
            }, config);
            setDictionary({...en, ...response.data.content.threadsView})
            setStatus('loaded')
        } catch (e) {
            setStatus('error')
            // publish the error to the analytics store
            publish(
                { resource: 'error' },
                { error: e, type: 'Threads view content load failure' }
            )
        }
    }

    const provider = {
        lang,
        dict: dictionary,
        status,
    };

    return <LangContext.Provider value={provider}>{children}</LangContext.Provider>
}

function useLang() {
    const context = React.useContext(LangContext);
    if (!context) {
        throw new Error('useLang can only be used within a LangContext provider');
    }
    return context;
}

export { LangProvider, useLang }