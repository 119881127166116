import React, { useEffect } from 'react';
import { useView } from './view';
import { useMessageDraft } from './messageDraft';

const AutoComposeContext = React.createContext(null);

function AutoComposeProvider({children}) {
    const { setManager } = useView();
    const { setTopicId, setSubject, setCustomFields } = useMessageDraft();

    useEffect(() => {
        if (window.SecureMessageAutoCompose) {
            try {
                const opts = window.SecureMessageAutoCompose.options;
                setTopicId(opts.topicId || '');
                setSubject(opts.subject || '');
                setCustomFields(opts.customFields || []);
                //setCustomFields(opts.customFields || []);
                setManager('compose');
            } catch (e) {
                console.error(
                    '[ERROR] invalid autoCompose - must be object with options'
                )
            } finally {
                // clear the auto compose
                window.SecureMessageAutoCompose = null
            }
        }
    }, [])

    const provider = {
    }

    return <AutoComposeContext.Provider value={provider}>{children}</AutoComposeContext.Provider>
}

function useAutoCompose() {
    const context = React.useContext(AutoComposeContext);
    if (!context) {
        throw new Error('useAutoCompose must be used within an AutoComposeProvider');
    }
    return context;
}

export { AutoComposeProvider, useAutoCompose }