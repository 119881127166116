import React, { useState } from 'react';
import { useAnalytics } from './analytics';

const ErrorsContext = React.createContext();

function ErrorsProvider({children}) {
    const { publish } = useAnalytics();
    const [errors, setErrors] = useState([]);

    const addError = (scope, error, e, analyticsReportingType) => {
        setErrors([...errors, {scope, error}]);
        // publish analytics for the error
        if (e && analyticsReportingType) {
            publish(
                { resource: 'error' },
                { error: e, type: analyticsReportingType }
            )
        }
    }

    const removeError = (index) => {
        const newErrors = [...errors];
        newErrors.splice(index, 1);
        setErrors(newErrors);
    }

    const provider = {
        errors,
        add: addError,
        removeError,
        clear: () => setErrors([])
    }

    return <ErrorsContext.Provider value={provider}>{children}</ErrorsContext.Provider>
}

function useErrors() {
    const context = React.useContext(ErrorsContext);
    if (!context) {
        throw new Error('useErrors must be used within an ErrorsContext provider')
    }
    return context;
}

export { ErrorsProvider, useErrors }