import moment from 'moment'

const DefaultConfig = {
  'thread:list': 'MMM D, YYYY',
  'message:header': 'MMM D, YYYY h:mm A',
  ada: 'MMMM Do YYYY, [Time:] h mm A' // used by screen readers
}

function DateFormatter(props) {
    //const config = { ...DefaultConfig, ...props.config }
	const {date,formatIdentifier} = props;
    const config = {
      ...DefaultConfig,
      ...(props.config || {})
    };   
      let format = config[formatIdentifier] || config.default
      if (formatIdentifier === 'iso') format = undefined // moment formats undefined as iso
      // This below code in not used in new messaging application
      /*if (typeof format === 'function') {
        return format(moment(date), moment(referenceDate))
      } */
      return moment(date).format(format)
}

export default DateFormatter;