import React from 'react';
import { useLang } from '@context/lang';
import { useThreads } from '@context/threads';
import { useView } from '@context/view';
import './Pagination.scss';

function Pagination() {
    return (
        <nav className='oms--pagination'>
            <PageDisplay />
            <PrevButton />
            <NextButton />
        </nav>
    )
}

function NextButton () {
    const { dict } = useLang();
    const { page, setPage } = useView();
    const { numPages } = useThreads();

    const isDisabled = page >= numPages
    return (
        <button
            aria-label={`${dict.nextLabel.a11y}: then tab to next message thread`}
            className='oms--page-button'
            onClick={() => setPage(page + 1)}
            disabled={page >= numPages}
			data-testid='oms-next-page'
            role='list-item'
            aria-current={isDisabled ? 'false' : 'true'}
        >
        {dict.nextLabel.label}
        </button>
    )
}
  
function PrevButton () {
    const { dict } = useLang();
    const { page, setPage } = useView();

    const isDisabled = page === 1

    return (
        <button
            aria-label={`${dict.prevLabel.a11y}: then tab to next message thread`}
            className='oms--page-button'
            onClick={() => setPage(page - 1)}
            disabled={isDisabled}
			data-testid='oms-prev-page'
            role='list-item'
            aria-current={isDisabled ? 'false' : 'true'}
        >
        {dict.prevLabel.label}
        </button>
    )
}

function PageDisplay () {
    const { dict } = useLang();
    const { page } = useView();
    const { numPages } = useThreads();

    return (
        <div
            className='oms--page-display'
            aria-label={dict.pageLabel.a11y}
			data-testid='oms-page-display'
        >
            {dict.pageLabel.label}
            <span className='oms--page' data-testid='oms-page'>
                {`${page} ${dict.ofLabel.label} ${numPages < 1 ? '1' : numPages}`}
            </span>
        </div>
    )
}

export default Pagination;
