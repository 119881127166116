import React, { useState } from 'react';

const ThreadFilterContext = React.createContext(null);

function ThreadFilterProvider({children}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [tags, setTags] = useState([]);
    const [refreshThreads, setRefreshThreads] = useState(false);

    const provider = {
        startDate, setStartDate, 
        endDate, setEndDate,
        tags, setTags,
        refreshThreads, setRefreshThreads
    }

    return <ThreadFilterContext.Provider value={provider}>{children}</ThreadFilterContext.Provider>
}

function useThreadFilter() {
    const context = React.useContext(ThreadFilterContext);
    if (!context) {
        throw new Error('useThreadFilter must be used within a ThreadFilterProvider');
    }
    return context;
}

export { ThreadFilterProvider, useThreadFilter }