import React from 'react'
import ContactDisplay from '@components/ContactDisplay/ContactDisplay';
import LabeledContentRow from '@components/LabeledContentRow/LabeledContentRow';
import { useLang } from '../../../context/lang';

function filterToType (type = 'to', recipients) {
  return recipients.filter(recipient => recipient.recipient_type === type)
}

function RecipientsOfType ({ label, typeDescriptor, recipients, ...props }) {
  const filtered = filterToType(typeDescriptor, recipients)
  if (filtered.length < 1) return <span />

  return (
    <LabeledContentRow additionalValueClass="label_content_to"  label={label} {...props}>
      {filtered.map((recipient, i) => (
        <ContactDisplay key={i} {...recipient} onReply='true' />
      ))}
    </LabeledContentRow>
  )
}

function Recipients ({ recipients, classNames, ...props }) {
    const { dict } = useLang();
  return (
    <div {...props}>
      <RecipientsOfType
        label={dict.toLabel.label}
        typeDescriptor='to'
        recipients={recipients}
        classNames={classNames}
      />
      <RecipientsOfType
        label={dict.ccLabel.label}
        typeDescriptor='cc'
        recipients={recipients}
        classNames={classNames}
      />
      <RecipientsOfType
        label={dict.bccLabel.label}
        typeDescriptor='bcc'
        recipients={recipients}
        classNames={classNames}
      />
    </div>
  )
}

export default Recipients;
