import React from 'react';
import './NoThreads.scss';
import lighthouse from '@assets/lighthouse.png';
import danelion from '@assets/danelion.png';
import { useLang } from '@context/lang';
import { useConfig } from '@context/config';

function NoThreads() {
    const { dict } = useLang();
    const { brand } = useConfig();

    return (
        <div className='noThreadsContainer' data-testid='no-threads-view'>
            {brand === 'optum' 
                ? <img className='lighthouse' src={lighthouse} alt="" />
                : <img className='danelion' src={danelion} alt="" />
            }
            <h2>{dict.noMessagesSplash.header}</h2>
            <p>{dict.noMessagesSplash.subHeader}</p>
        </div>
    )
}

export default NoThreads;