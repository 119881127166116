import React, { useEffect, useRef } from 'react';
import { useLang } from '@context/lang';
import './CancelDialog.scss';
import { useAnalytics } from '../../../context/analytics';

function CancelDialog(props) {
    const { dict } = useLang();
    const { viewStart, viewEnd, publish } = useAnalytics();
    let focusable = null
    const closeBtnRef = useRef(null);
    const headerRef = useRef(null);
    const stayBtnRef = useRef(null);
    const leaveBtnRef = useRef(null);
    
    useEffect(() => {
        focusable = [closeBtnRef, stayBtnRef, leaveBtnRef];
        // setup an event listener for key down events
        let evtListener = document.addEventListener('keydown', e => {
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9

            if (!isTabPressed) {
                return
            }
    
            let idx = focusable.findIndex(e => e.current === document.activeElement)
            if (idx !== -1) {
                if (e.shiftKey) {
                    if (idx <= 0) {
                        idx = focusable.length - 1
                    }
                    focusable[idx - 1].current.focus()
                } else {
                    if (idx === -1 || idx === focusable.length - 1) {
                        focusable[0].current.focus()
                    } else {
                        focusable[idx + 1].current.focus()
                    }
                }
                e.preventDefault()
            }
        })
        // set initial focus on the close button
        closeBtnRef.current.focus()
    
        return () => {
            document.removeEventListener('keydown', evtListener)
        }
    }, [])

    useEffect(() => {
        viewStart('modal');
        publish({resource: 'compose', type: 'cancel'});
        viewEnd('modal');
    }, [])

    return (
        <div aria-label='Confirm cancellation of message.'>
            <div className='oms--underlay'></div>
                <div role="dialog" className='oms--sm-modal' aria-describedby="Opened Cancel Modal"  aria-modal>
                <div className='oms--sm-content oms--position-relative'>
                    <div style={{ position:'absolute', top:'32px', right:'32px'}}>
                        <button onClick={props.onCancel} className='oms--tertiary' ref={closeBtnRef} data-testid='closer-button'>
                            {dict.closeLabel.label}
                        </button>
                    </div>
                    <h1 aria-label={dict.cancelModal.heading} ref={headerRef}>{dict.cancelModal.heading}</h1>
                    <p  tabIndex="0" aria-label={dict.cancelModal.message} style={{ marginTop: '26px', marginBottom: '32px' }}>
                    {dict.cancelModal.message}
                    </p>
                    <button
                        id='modal-cancel-button'
                        onClick={props.onCancel}
                        className='oms--primary'
                        aria-label={dict.cancelModal.stayA11y}
                        ref={stayBtnRef}
                    >
                    {dict.cancelModal.stayLabel}
                    </button>
                    <button
                        id='modal-continue-button'
                        onClick={props.onContinue}
                        className='oms--secondary'
                        aria-label={dict.cancelModal.leaveA11y}
                        ref={leaveBtnRef}
                    >
                    {dict.cancelModal.leaveLabel}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CancelDialog;
