import React from 'react';
import { useLang } from '@context/lang';
import { useView } from '@context/view';
import './ComposeButton.scss';
import { useMessageDraft } from '../../context/messageDraft';

function ComposeButton() {
    const { dict } = useLang();
    const { setManager } = useView();
    const { setSubject } = useMessageDraft();

    const handleClick = () => {
        setSubject('');
        setManager('compose');
    }

    return (
        <div className='oms--compose-button-container'>
            <button
                className='oms--new-message-button'
                onClick={handleClick}
				aria-label={dict.composeButton.ally}
                data-testid='oms-compose-btn'
            >
                <span>{dict.composeButton.label}</span>
            </button>
        </div>
    )
}

export default ComposeButton;
