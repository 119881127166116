import React, { forwardRef } from 'react';

const Select = forwardRef(({field, hasError, errorMessage, id, value, onChangeHandler, onBlurHandler}, ref) => {

    const valueForOpt = (opt) => {
        return opt.defaultValue == 'true'
            ? ''
            : opt.value
            ? opt.value
            : opt.name
            ? opt.name
            : opt.text
    }

    let ariaLabel = field.label;
    let ariaDescribedBy = null;
    if (field.hint) {
        ariaLabel += `. ${field.hint}`
        ariaDescribedBy = `hint_message_${id}`
    }

    if (hasError) {
        ariaDescribedBy = id + '_error';
    }

    return (
        <div>
            <select
                ref={ref} 
                id={id} 
                value={value} 
                className={errorMessage ? ' oms--red-ring' : ''}
                onChange={onChangeHandler} onBlur={onBlurHandler}
                aria-label={ariaLabel}
                aria-required={field.required || false}
                aria-describedby={ariaDescribedBy}>
                {field.options.map((opt, idx) => {return <option key={idx.toString()} value={valueForOpt(opt)}>{opt.text}</option>})}
            </select>
        </div>
    )
})
    
export default Select;
    