import React, { useEffect, useState } from 'react';
import axios from '@util/axios_config';
import { useConfig } from './config';

const FiltersContext = React.createContext(null);

function FiltersProvider({children}) {
    const { token } = useConfig();
    const [filters, setFilters] = useState([]);
    const [status, setStatus] = useState('loading');
    
    useEffect(() => {
        loadFilterData();
    }, [])

    const loadFilterData = async () => {
        try {
            let config = {};
            if (token) {
                config.headers = {Authorization: `Bearer ${token}`};
            }
            const { contentUrl, portal, language } = axios;
            let response = await axios.post(contentUrl, {
              portal,
              language,
              components: ['filters']
            }, config)
            setFilters(response.data.content.filters.filters)
            setStatus('loaded')
          } catch (e) {
            setStatus('error')
            
          } finally {
            
          }
    }

    const provider = {
        filters,
        status,
    }

    return <FiltersContext.Provider value={provider}>{children}</FiltersContext.Provider>
}

function useFilters() {
    const context = React.useContext(FiltersContext);
    if (!context) {
        throw new Error('usesFilter must be used within a FiltersProvider');
    }
    return context;
}

export { FiltersProvider, useFilters }