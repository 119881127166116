import React, { useState, useEffect } from 'react'
import { useConfig } from './config'

const AnalyticsContext = React.createContext()

function AnalyticsProvider ({ children }) {
    const { portal, language } = useConfig();
    const [position, setPosition] = useState('inbox')
    
    useEffect(() => {
        console.log(
            'initializing analytics with portal:' + portal + ',language:' + language
        )
    }, [])
    
    const _logToConsole = (name, obj) => {
        if (window.sessionStorage.getItem('sm-analytics-logging') === 'true') {
            if (obj) {
                obj = JSON.stringify(obj, false, 4)
                console.log(name, obj)
            } else {
                console.log(name)
            }
        }
    }
    
    const viewStart = view => {
        let viewstart = new CustomEvent('viewstart', {
            detail: {
                view_name: view || 'inbox'
            }
        })
        document.body.dispatchEvent(viewstart)
        _logToConsole(viewstart.type, viewstart.detail.view_name)
    }
    
    const viewEnd = view => {
        let viewend = new CustomEvent('viewend', {
            detail: {
                view_name: view || position
            }
        })
        document.body.dispatchEvent(viewend)
        _logToConsole(viewend.type, viewend.detail.view_name)
    }
    // Info structure for actions associated with Target view changes
    const _targetInfoTemplate = (type, additionalProps = {}, specificPageName) => {
        const section = `globalnav_${portal}_secure message_${type}`
    
        return {
            content: {
                pageName: specificPageName || type,
                referringSiteSection: section,
                businessUnit: 'optum',
                website: portal,
                language: language,
                referringSite: portal,
                ...additionalProps
            },
            user: { userType: 'consumer', loginStatus: 'loggedin' }
        }
    }
    // Info structure for normal analytics calls
    const _nonTargetInfoTemplate = (type, additionalProps = {}) => {
        const section = `globalnav_${portal}_secure message_${type}`
       
        return {
            content: {
                referringSiteSection: section,
                website: portal,
                language: language,
                referringSite: portal,
                ...additionalProps
            },
            actions: { navLinkName: section },
            user: { userType: 'consumer', loginStatus: 'loggedin' }
        }
    }
        
    const _errorTemplate = (type, errorCode) => {
        return {
            content: {
                mailPosition: position,
                errorType: `${portal}_secure message:${type}`,
                pageName: 'error page',
                errorCode: `${portal}_secure message:${errorCode}`
            },
            user: { userType: 'consumer', loginStatus: 'loggedin' }
        }
    }
        
    const _formErrorTemplate = (type, errorFields, errorCode) => {
        return {
            content: {
                errorType: `${portal}_secure message:${type}`,
                errorFields: `${errorFields}`,
                errorCode: `${portal}_secure message:${errorCode}`
            },
            user: { userType: 'consumer', loginStatus: 'loggedin' }
        }
    }
        
    const _getField = (messageDraft, name, property = 'value') => {
        const fields = messageDraft.customFields ? messageDraft.customFields : [];
        const field = fields.find(field => field.name === name)
        return (field && field[property]) ? field[property] : '';
    }
        
    const _getTellUsMore = messageDraft => {
        const fields = messageDraft.customFields ? messageDraft.customFields : [];
        if (fields.find(field => field.name === 'tellUsALittleMore')) {
            return _getField(messageDraft, 'tellUsALittleMore')
        } else {
            return _getField(messageDraft, 'category', 'analytics')
        }
    }
        
    const _populateDynamicFields = messageDraft => {
        const fields = messageDraft.customFields ? messageDraft.customFields : [];
        return fields
            .filter(
                field =>
                    field !== undefined &&
                    field.name !== 'topic' &&
                    field.name !== 'tellUsALittleMore' &&
                    field.name !== 'category'
            )
            .map(field => field.name)
            .join('|')
    }
        
    const _publishPostPageData = window.gnPublishPostPageData || _logToConsole

    const _preFlightCheck = () => {
        // guarantee that the window.gnPageDataLayer is defined
        if (!window.gnPageDataLayer) {
            window.gnPageDataLayer = {
                content: {
                    pageName: '',
                    siteSectionL1: '',
                    siteSectionL2: '',
                    siteSectionL3: '',
                    businessUnit: 'optum',
                    website: 'secure messaging'
                },
                actions: {
                    navLinkName: ' '
                },
                user: {
                    userType: 'consumer',
                    loginStatus: 'loggedin'
                }
            }
        }
    }
    
    const upperFirst = (str) => {
        if (str && str.length > 0) {
            const firstChar = str.charAt(0).toUpperCase();
            return (firstChar + str.substring(1));
        }
        return '';
    }

    const publish = (action, context) => {
        _preFlightCheck()
        try {
            switch (action?.resource) {
                case 'messages':
                if (action.type !== 'cancel') {
                    _publishPostPageData(
                        'secureMessageReadMessage',
                        _targetInfoTemplate('read message', {
                            siteSectionL1: position
                        })
                        )
                    }
                    if (action.type === 'cancel') {
                        _publishPostPageData(
                            'globalNavSm',
                            _nonTargetInfoTemplate(`${action.currentMode} cancel`)
                        )
                    }
                    break
                case 'threads':
                    if (action.type === 'cancel') {
                    _publishPostPageData(
                        'globalNavSm',
                        _nonTargetInfoTemplate(`${action.currentMode} cancel`)
                        )
                    } else {
                        _publishPostPageData(
                            `secureMessage${upperFirst(action.folderType)}`,
                            _targetInfoTemplate(action.folderType)
                        )
                    }
                    break;
                case 'reply':
                case 'compose':
                    if (action.type === 'cancel') {
                        // need to make call stating that the user clicked on the cancel button or the folder links
                        _publishPostPageData(
                            'secureMessageModal',
                            _targetInfoTemplate(`${action.resource}_modal`, null, 'modal')
                        )
                    } else if (action.type?.startsWith('modal ')) {
                        _publishPostPageData(
                            `globalNavSm`,
                            _nonTargetInfoTemplate(`${action.resource}_${action.type}`)
                        )
                    } else {
                        _publishPostPageData(
                            `secureMessage${upperFirst(action.resource)}`,
                            _targetInfoTemplate(action.resource)
                        )
                    }
                    break;
                case 'archive':
                    _publishPostPageData(
                        'globalNavSm',
                        _nonTargetInfoTemplate(
                            action.destinationFolderType === 'archive'
                                ? 'move to archive'
                                : 'move to inbox'
                        )
                    )
                    break
                case 'send':
                    if (context.isReply) {
                        _publishPostPageData(
                            'globalNavSmReply',
                            _nonTargetInfoTemplate('reply send')
                        )
                    } else {
                        _publishPostPageData(
                            'globalNavSmSend',
                            _nonTargetInfoTemplate('compose send', {
                                messageTopic: _getField(context.messageDraft, 'topic'),
                                tellUsMore: _getTellUsMore(context.messageDraft),
                                dynamicFormFields: _populateDynamicFields(context.messageDraft)
                            })
                        )
                    }
                    break
                case 'error':
                    const type = context.type;
                    _publishPostPageData(
                        'sm_errorTracking',
                            _errorTemplate(
                                type || 'Network Failed',
                                (context.error.response.status)
                                    ? context.error.response.status
                                    : 'Network Failed'
                            )
                    )
                    break;
                case 'fields':
                    const type2 = context.type;
                    _publishPostPageData(
                        'globalNavSm_uiErrors',
                            _formErrorTemplate(
                                type2 || 'Form Fields Error',
                                context.fields,
                                context.name
                            )
                    )
                    break;
            }
        } catch (e) {
            console.error('Unable to post analytics', e)
        }
    }
                                                                    
    const provider = {
        viewStart,
        viewEnd,
        setPosition,
        publish,
    }
    
    return <AnalyticsContext.Provider value={provider}>{children}</AnalyticsContext.Provider>
}
                                                                
function useAnalytics () {
    const context = React.useContext(AnalyticsContext)
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider')
    }
    return context
}

export { AnalyticsProvider, useAnalytics }
                                                                