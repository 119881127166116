import React, { useEffect, useState, useContext } from 'react';
import axios from '@util/axios_config';
import { useConfig } from './config';

const MailBoxContext = React.createContext(null);

const MailBoxesProvider = (props) => {
    const { token } = useConfig();
    const [loadingMailboxes, setLoadingMailboxes] = useState(true);
	const [mailBoxes, setMailBoxes] = useState([]);
	
	useEffect(() => {
		loadMailboxes();
	}, []);
	
	const loadMailboxes = async () => {
        setLoadingMailboxes(true);
        try {
            let config = {}
            if (token) {
                config.headers = {Authorization: `Bearer ${token}`};
            }
            const response = await axios.get('mailboxes', config);
            setMailBoxes(response.data.mailbox);
          } catch (e) {
            console.log('error logged while fetching mailboxes', e);
          } finally {
            setLoadingMailboxes(false);
          }
    }
	const provider = {
		loadingMailboxes, mailBoxes
	};
	return <MailBoxContext.Provider value={provider}>{props.children}</MailBoxContext.Provider>
}

const useMailBoxes = () => {
	const context = useContext(MailBoxContext);
	if(!context) {
		throw new Error('useMailBoxes must be used within a MailBoxContext provider');
	}
	return context;
}


export { useMailBoxes, MailBoxesProvider } 
