import React, { useEffect, useRef } from 'react';
import './TopicPicker.scss';
import { useFilters } from '@context/filter';
import { useThreadFilter } from '@context/threadFilter';
import { useLang } from '@context/lang';

function TopicPicker({show, onHide}) {
    const { dict } = useLang();
    const { filters } = useFilters();
    const { tags, setTags } = useThreadFilter();
    const topicRef = useRef([]);
    const resetButtonRef = useRef(null);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (show) {
            topicRef.current[0]?.focus();
        }
    }, [show])

    const toggleTopicTag = (event, topicId, label) => {
        const tagIndex = tags.findIndex(tag => tag.id === topicId);
        if (tagIndex === -1) {
            setTags([...tags, {id: topicId, label}]);
        } else {
            setTags(tags.toSpliced(tagIndex, 1));
        }
        event.stopPropagation();
        event.preventDefault();
    }

    const clearSelectedTagsHandler = (event) => {
        setTags([]);
        event.stopPropagation();
        event.preventDefault();
    }

    const addTopicRef = (element) => {
        const count = topicRef.current.length;
        topicRef.current[count] = element;
    }

    const keyDownHandler = (event, topicId, label) => {
        let isTabPressed = event.key === 'Tab' || event.keyCode === 9;
        let isShiftPressed = event.key === 'Shift';
        let isEnterPressed = event.key === 'Enter';
        console.log('Key:', event.key);

        if (isTabPressed || isShiftPressed || isEnterPressed) {
            let idx = topicRef.current.findIndex(e => 
                e.id === event.currentTarget.id)
            if (isTabPressed) {
                if (idx !== -1) {
                    if (event.shiftKey) {
                        if (idx <= 0) {
                            closeButtonRef.current.focus();
                        } else {
                            topicRef.current[idx - 1].focus()
                        }
                    } else {
                        const nextRef = topicRef.current[idx + 1];
                        if (!nextRef) {
                            resetButtonRef.current.focus();
                        } else {
                            nextRef.focus()
                        }
                    }
                }
            } else if (isEnterPressed) {
                toggleTopicTag(event, topicId, label);
            }
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const clearButtonKeyDownHandler = (event) => {
        let isTabPressed = event.key === 'Tab' || event.keyCode === 9;
        let isShiftPressed = event.key === 'Shift';
        let isEnterPressed = event.key === 'Enter';

        if (isTabPressed || isShiftPressed) {
            if (isTabPressed) {
                if (event.shiftKey) {
                    if (event.currentTarget == resetButtonRef.current) {
                        topicRef.current[topicRef.current.length - 1].focus();
                    } else {
                        resetButtonRef.current.focus();
                    }
                } else {
                    if (event.currentTarget == closeButtonRef.current) {
                        topicRef.current[0].focus();
                    } else {
                        closeButtonRef.current.focus();
                    }
                }
            }
        } else if (isEnterPressed) {
            clearSelectedTagsHandler(event);
        }
        event.stopPropagation();
        event.preventDefault();
    }

    return (
        <div className={'oms--topic-picker-dialog' + (show ? ' show' : '')} onClick={(evt) => evt.preventDefault()}>
            <div className='oms--topic-picker-container'>
                {filters?.length > 0 ?
                    <>
                        {filters.map((grp, i) => 
                            <fieldset key={i}>
                                <legend>{grp.text}</legend>
                                {grp.topics.map((t, j) => {
                                    return (
                                        <div key={j} className='oms-topic-checkbox'>
                                            <input id={t.topicId} 
                                                type='checkbox' 
                                                checked={tags.findIndex(elem => elem.id === t.topicId) > -1}
                                                ref={addTopicRef}
                                                onChange={(event) => toggleTopicTag(event, t.topicId, t.text)}
                                                onKeyDown={(event) => keyDownHandler(event, t.topicId, t.text)} />
                                            <label htmlFor={t.topicId}>{t.text}</label>
                                        </div>
                                    )
                                })}
                            </fieldset>
                        )}
                        <div className='oms--filter-footer'>
                            <button 
                                onClick={clearSelectedTagsHandler}
                                onKeyDown={clearButtonKeyDownHandler}
                                ref={resetButtonRef}>{dict.filters.reset}</button>
                            <button 
                                className='oms--filter-close' 
                                onClick={onHide}
                                onKeyDown={clearButtonKeyDownHandler}
                                ref={closeButtonRef}>{dict.filters.close}</button>
                        </div>
                    </>
                    :
                    <div className='oms--no-topics'>{dict.filters.noTopics}</div>
                }
            </div>
        </div>
    )
}

export default TopicPicker;