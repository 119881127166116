import React from 'react'

import './FieldNotification.scss'

function FieldNotification(props) {
    return (
        <div className='oms--field-notification-outer'>
            <div className='oms--field-notification-inner'>
                <div className='oms--fn-icon'>
                    <span className='sm-icon icon--flag'/>
                </div>
                <div className='oms--fn-message' role='alert' aria-live='assertive'>
                    <span className='oms--fn-message-title'>
                        {props.notification.title + ': '}
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: props.notification.content }} />
                </div>
            </div>
        </div>
    )
}
    
export default FieldNotification
    