import React, { useState, useEffect } from 'react'
import './EmphasisButton.scss'

function EmphasisButton (props) {
    const [emphasis] = useState(props.emphasis || 'high')
    const [btnProps, setBtnProps] = useState({})
    
    useEffect(() => {
        let myProps = { ...props }
        delete myProps.onClick
        delete myProps.className
        delete myProps.disabled
        delete myProps.threadsViewStore
        setBtnProps(myProps)
    }, [])
    
    return (
        <button
            onClick={props.onClick}
            className={`oms--emphasis-button ${emphasis}`}
            disabled={props.disabled}
            {...btnProps}
            type='button'
        >
            <div className='oms--button-label-container'>
                <div className='oms--button-label'>{props.children}</div>
            </div>
        </button>
    )
}
    
export default EmphasisButton
    