import React from 'react';
import { useConfig } from '@context/config';
import ViewManager from '@components/ViewManager';
import Errors from '@components/Errors';
import '../App.scss';
import './Widget.scss';

import { ViewProvider } from '@context/view';
import { ErrorsProvider } from '@context/errors';
import { MessageDraftProvider } from '@context/messageDraft';
import Heading from './Heading';

function Widget() {
    const { brand } = useConfig();

    return (
        <div className={`oms--top-level ${brand}`}>
            <ErrorsProvider>
                <ViewProvider>
                    <MessageDraftProvider>
                        <Errors />
                        <Heading />
                        <ViewManager />
                    </MessageDraftProvider>
                </ViewProvider>
            </ErrorsProvider>
        </div>
    )
}

export default Widget;
