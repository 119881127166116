import React, { useState, useEffect } from 'react';
import { useAnalytics } from './analytics';

const ViewContext = React.createContext();

function ViewProvider({children}) {
    const { setPosition, publish } = useAnalytics();
    const [manager, setManager] = useState('browse');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [folder, setFolder] = useState('inbox');
    const [page, setPage] = useState(1);
    const [thread, setThread] = useState(null);
    const [threadTitle, setThreadTitle] = useState('');
    const [lastViewAction, setLastViewAction] = useState(null);

    useEffect(() => {
        setLastViewAction({
            clear: true,
            folderType: 'inbox',
            page: 1,
            resource: 'threads',
            type: '',
            userClick: true
          })
    }, [])

    useEffect(() => {
        setPosition(folder);
        const viewAction = {
            clear: true,
            folderType: folder,
            page: page,
            resource: 'threads',
            type: '',
            userClick: true
        };
        setLastViewAction(viewAction);
        setThread(null);
    }, [folder])

    useEffect(() => {
        if (lastViewAction) {
            publish(lastViewAction);
        }
    }, [lastViewAction])

    const provider = {
        manager,
        setManager,
        folder,
        setFolder,
        page,
        setPage,
        thread,
        setThread,
        threadTitle,
        setThreadTitle,
        notificationMessage,
        setNotificationMessage
    }

    return <ViewContext.Provider value={provider}>{children}</ViewContext.Provider>
}

function useView() {
    const context = React.useContext(ViewContext);
    if (!context) {
        throw new Error('useView can only be used within a ViewContext provider');
    }
    return context;
}

export { ViewProvider, useView }
