import React from 'react'
import './ScreenReaderOnly.scss'

/**
 * Alternative text when aria-label will not work
 * @param [props]
 * @param [props.message='']
 * @param [props.children=message] children to be shown 'off-screen' only visible to assistive devices
 * @returns {null|XML}
 */
export default function ScreenReaderOnly ({
  message = '',
  children = message
}) {
  if (children === '') {
    return null
  }
  return <div className='sr-only'>{children}</div>
}
