import React, { useState, useEffect } from 'react';
import { useErrors } from '@context/errors';
import { useLang } from '@context/lang';
import './Errors.scss';

function Errors(props) {
    const { errors, removeError }  = useErrors();
    const [errorGroup, setErrorGroup] = useState([]);

    useEffect(() => {
        if (errors) {
            setErrorGroup([...errors]);
        }
    }, [errors]);

    return (
        <React.Fragment>
            {errors && errors.length > 0 ?
            <div className='oms--error-list-container oms--error' {...props}>
                <ul aria-live='assertive'>
                    {errorGroup.length > 0 && errorGroup.map((error, index) => (
                    <ErrorGroup
                        message={error.error}
                        key={index}
                        onDismiss={() => removeError(index)}
                    />
                    ))}
                </ul>
            </div>
            : null
            }
        </React.Fragment>
    )
}

function ErrorGroup ({ message, onDismiss }) {
    const { dict } = useLang();
    
    return (
      <li>
        <div className='oms--item' role='alert' aria-live='polite'>
            <div className='oms--error-details'>
                <span className="sm-icon icon--alert_filled" />
                <span className='oms--error-text'>
                    <span className='oms--error-key'>{dict.errorMsgs.sysErr} </span>
                    {message}
                </span>
            </div>
            <div className='oms--error-dismiss-container'>
                <button role='button' className='oms--error-dismiss' onClick={onDismiss}>{dict.dismissLabel.label}</button>
            </div>
        </div>
      </li>
    )
  }

export default Errors;
