import React from 'react';

import './LabeledContentRow.scss'

function LabeledContentRow({label, children, additionalValueClass = ''}) {
    return (
        <div className={`labeled_content_row ${additionalValueClass}`}>
            <div className='labeled_content_name'>{label}</div>
            <div className={`labeled_content_value`}>{children}</div>
        </div>
    )
}

export default LabeledContentRow;
